import React from "react";
import "./Footer.css";
import Tada from "react-reveal/Tada";
import { BsLinkedin, BsGithub, BsTwitter } from "react-icons/bs";

const Footer = () => {
  return (
    <footer className="footer">
      <Tada>
        <div className="footer-content">
          <p>
            &copy; {new Date().getFullYear()} Moeez Abdul. All rights reserved.
          </p>
          <div className="footer-social-icons">
            <a
              href="https://www.linkedin.com/in/moeezabdul/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Link to Moeez Abdul's LinkedIn profile" // Accessible label for LinkedIn
            >
              <BsLinkedin color="#f0a202" size={30} aria-hidden="true" />
            </a>
            <a
              href="https://github.com/Mo-Gamer-2000"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Link to Moeez Abdul's GitHub profile" // Accessible label for GitHub
            >
              <BsGithub color="#f0a202" size={30} aria-hidden="true" />
            </a>
            <a
              href="https://twitter.com/MoeezRajput10"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Link to Moeez Abdul's Twitter profile" // Accessible label for Twitter
            >
              <BsTwitter color="#f0a202" size={30} aria-hidden="true" />
            </a>
          </div>
        </div>
      </Tada>
    </footer>
  );
};

export default Footer;
