import React from "react";
import "./Menus.css";
import Zoom from "react-reveal/Zoom";
import Fade from "react-reveal/Fade";
import { Link } from "react-scroll";
import images from "../../constants/images";
import {
  FcAbout,
  FcBiotech,
  FcBusinessContact,
  FcBusinessman,
  FcHome,
  FcReading,
  FcStackOfPhotos,
} from "react-icons/fc";
import { Helmet } from "react-helmet";

const MenuLink = ({ to, icon: Icon, children }) => (
  <div className="nav-link">
    <Link to={to} spy={true} smooth={true} offset={-100} duration={100}>
      <Icon />
      {children}
    </Link>
  </div>
);

const Menus = ({ toggle }) => {
  const menuLinks = (
    <div className="nav-items">
      <div className="nav-item">
        <MenuLink to="home" icon={FcHome}>
          Home
        </MenuLink>
        <MenuLink to="about" icon={FcAbout}>
          About Me
        </MenuLink>
        <MenuLink to="education" icon={FcReading}>
          Education
        </MenuLink>
        <MenuLink to="teckstack" icon={FcBiotech}>
          Tech Stack
        </MenuLink>
        <MenuLink to="projects" icon={FcStackOfPhotos}>
          Projects
        </MenuLink>
        <MenuLink to="workExp" icon={FcBusinessman}>
          Work Experience
        </MenuLink>
        <MenuLink to="contact" icon={FcBusinessContact}>
          Contact Me
        </MenuLink>
      </div>
    </div>
  );

  const menuNoNames = (
    <div className="nav-items">
      <div className="nav-item">
        <MenuLink to="home" icon={FcHome}></MenuLink>
        <MenuLink to="about" icon={FcAbout}></MenuLink>
        <MenuLink to="education" icon={FcReading}></MenuLink>
        <MenuLink to="teckstack" icon={FcBiotech}></MenuLink>
        <MenuLink to="projects" icon={FcStackOfPhotos}></MenuLink>
        <MenuLink to="workExp" icon={FcBusinessman}></MenuLink>
        <MenuLink to="contact" icon={FcBusinessContact}></MenuLink>
      </div>
    </div>
  );

  return (
    <>
      <Helmet>
        <link rel="preload" href={images.menuImg} as="image" />
      </Helmet>
      {toggle ? (
        <>
          <Zoom>
            <div className="navbar-profile-pic">
              <img
                src={images.menuImg}
                alt="Moeez Abdul"
                width="246px"
                height="327px"
              />
              <p>Moeez Abdul</p>
            </div>
          </Zoom>
          <Fade left>{menuLinks}</Fade>
        </>
      ) : (
        menuNoNames
      )}
    </>
  );
};

export default Menus;
