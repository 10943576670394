import React, { useEffect } from "react";
import { useTheme } from "../../context/ThemeContext";
import Typewriter from "typewriter-effect";
import CV from "../../assets/docs/CV-Moeez-Abdul.pdf";
import { BsFillMoonStarsFill, BsFillSunFill } from "react-icons/bs";
import Fade from "react-reveal/Fade";
import Roll from "react-reveal/Roll";

import "./Home.css";

const Home = () => {
  const [theme, setTheme] = useTheme();

  // Handle Theme
  const handleTheme = () => {
    setTheme((prevState) => {
      const newTheme = prevState === "light" ? "dark" : "light";
      localStorage.setItem("theme", newTheme);
      return newTheme;
    });
  };

  useEffect(() => {
    const savedTheme = localStorage.getItem("theme");
    if (savedTheme) {
      setTheme(savedTheme);
    }
  }, []);

  return (
    <>
      <div className="container-fluid home-container" id="home">
        <div className="theme-btn" onClick={handleTheme}>
          {theme === "light" ? (
            <BsFillMoonStarsFill size={30} />
          ) : theme === "dark" ? (
            <BsFillSunFill size={30} />
          ) : null}
        </div>

        <div className="container home-content">
          <Fade right>
            <h2>Console.log("Hello,W🌏rld. I am a:");</h2>
            <h1>
              <Typewriter
                options={{
                  strings: [
                    "Moeez Abdul",
                    "Software Development Graduate",
                    "Full-Stack Developer",
                  ],
                  autoStart: true,
                  loop: true,
                }}
              />
            </h1>
          </Fade>
          <Roll left>
            <div className="home-buttons">
              <a
                className="btn btn-hire"
                href="mailto:Moeezrajput2000@gmail.com"
                target="_blank"
                rel="noreferrer"
              >
                Hire Me
              </a>
              <a
                className="btn btn-cv"
                href={CV}
                download="Moeez_CV.pdf"
                title="cv"
              >
                My CV
              </a>
            </div>
          </Roll>
        </div>
      </div>
    </>
  );
};

export default Home;
