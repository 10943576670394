import Profile from "../assets/images/profileImg.webp";
import Contact from "../assets/images/contactImg.webp";
import menuImg from "../assets/images/menuImg.webp";

const images = {
  Profile,
  Contact,
  menuImg,
};

export default images;
