import React from "react";
import "./App.css";
import Footer from "./components/Footer/Footer";
import Layout from "./components/Layout/Layout";
import ScrollToTop from "react-scroll-to-top";
import { useTheme } from "./context/ThemeContext";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Using React.lazy to load components as they are needed
const About = React.lazy(() => import("./pages/About/About"));
const Contact = React.lazy(() => import("./pages/Contact/Contact"));
const Education = React.lazy(() => import("./pages/Education/Education"));
const Projects = React.lazy(() => import("./pages/Projects/Projects"));
const Teckstack = React.lazy(() => import("./pages/Teckstack/Teckstack"));
const WorkExp = React.lazy(() => import("./pages/WorkExp/WorkExp"));

function App() {
  const [theme] = useTheme();
  return (
    <>
      <div id={theme}>
        <ToastContainer />
        <Layout />
        <div className="container ">
          {/* Wrapping the lazy-loaded components with Suspense */}
          <React.Suspense fallback={<div>Loading...</div>}>
            <div className="section">
              <About />
            </div>
            <div className="section">
              <Education />
            </div>
            <div className="section">
              <Teckstack />
            </div>
            <div className="section">
              <Projects />
            </div>
            <div className="section">
              <WorkExp />
            </div>
            <div className="section">
              <Contact />
            </div>
          </React.Suspense>
        </div>
        <Footer />
      </div>
      <ScrollToTop
        smooth
        color="#0e1428"
        style={{ backgroundColor: "#f0a202", borderRadius: "80px" }}
      />
    </>
  );
}

export default App;
